<template>
    <v-container>
      <v-layout wrap>

        <v-flex xs12 sm12 md12 lg12 xl12 grow pa-1>
          <ICTSolutionIncomeBySectorChart
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
            ]"
          />
        </v-flex>        
<!--
        <v-flex xs12 sm12 md12 lg12 xl12 grow pa-1>
          <ICTSolutionIncomeFromCRMDataBySectorChart
            :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
              '60',
              '61',
              '63',
              '66',
            ]"
          />
        </v-flex>
-->
      <v-flex xs12 sm12 md12 lg12 xl12 grow pa-1>
        <MonthlyICTSolutionIncomeBySectorLineChart
          :officeSectorID="[
              '17',
              '18',
              '19',
              '20',
              '21',
              '22',
              '23',
              '24',
              '25',
              '26',
              '27',
              '28',
              '29',
              '58',
            ]"
        />
      </v-flex>
      </v-layout>
    </v-container>
  </template>
  
  <script>
  import ICTSolutionIncomeBySectorChart from "./ICTSolutionIncomeBySectorChart.vue";
  // import ICTSolutionIncomeFromCRMDataBySectorChart from "./ICTSolutionIncomeFromCRMDataBySectorChart.vue";
  import MonthlyICTSolutionIncomeBySectorLineChart from "./MonthlyICTSolutionIncomeBySectorLineChart.vue";
  
  export default {
    name: "ICTSolutionIncomeDashboard",
    components: {
      ICTSolutionIncomeBySectorChart,
      // ICTSolutionIncomeFromCRMDataBySectorChart,
      MonthlyICTSolutionIncomeBySectorLineChart
  
    },
    props: {

    },
  };
  </script>
  

  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>